<template>
    <VVVLayout>
        <MDBCard>
            <MDBCardHeader>
                <h3 class="my-auto py-1" style="font-size: 20px;">Bulk Claims</h3>
            </MDBCardHeader>
            <MDBCardBody class="pb-2">
                <MDBAlert color="success" static class="px-4 py-3" style="font-size: 14.5px;">
                    <i class="fas fa-check-circle me-2"></i>
                    Our <strong>Bulk Claims Sheet</strong> is best for submitting multiple claims at once. By filling
                    out multiple rows
                    on the sheet, you can seamlessly sync it to your VVV dashboard!
                </MDBAlert>
                <h6 class="pt-3 pb-1">Your bulk claim sheet :</h6>
                <MDBInput inputGroup :formOutline="false" wrapperClass="mb-3" v-model="shortenSheetUrl">
                    <MDBBtn class="btn btn-primary" @click="copy(shortenSheetUrl)" title="Copy to Clipboard">
                        <MDBIcon icon="copy" class="trailing" />
                    </MDBBtn>
                    <MDBBtn class="btn btn-primary" @click="redirectToSheet" title="Open in new Tab">
                        <MDBIcon icon="arrow-right" class="trailing" />
                    </MDBBtn>
                </MDBInput>
                <!-- <h6 class="pt-4 pb-2">Watch the video guide below on how to get start!</h6> -->
                <!-- <MDBSelect v-model:options="videoUrlList" v-model:selected="selectedLanguage" label="Select Language" /> -->
                <!-- <video id="local-video" width="640" height="480" controls class="pt-1"> <source :src="videoUrl" type="video/mp4"> Your browser does not support the video tag. </video> -->
            </MDBCardBody>
        </MDBCard>
    </VVVLayout>
</template>

<script setup>
import VVVLayout from "@/components/VVV/VVVLayout.vue";
import { useUserDetailStore } from "@/store/userDetailStore";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBInput, MDBIcon, MDBBtn, MDBAlert } from "mdb-vue-ui-kit";
import { useTitle, useClipboard } from "@vueuse/core";
import { ref, onMounted, watch, computed } from "vue";

import dashboardGuideEn from "@/assets/videos/vvv-dashboard-guide-en.mp4";
import dashboardGuideVi from "@/assets/videos/vvv-dashboard-guide-vi.mp4";
import dashboardGuideZh from "@/assets/videos/vvv-dashboard-guide-zh.mp4";
import dashboardGuideNl from "@/assets/videos/vvv-dashboard-guide-nl.mp4";
import dashboardGuideFr from "@/assets/videos/vvv-dashboard-guide-fr.mp4";
import dashboardGuideDe from "@/assets/videos/vvv-dashboard-guide-de.mp4";
import dashboardGuideId from "@/assets/videos/vvv-dashboard-guide-id.mp4";
import dashboardGuideIt from "@/assets/videos/vvv-dashboard-guide-it.mp4";
import dashboardGuideJa from "@/assets/videos/vvv-dashboard-guide-ja.mp4";
import dashboardGuideKo from "@/assets/videos/vvv-dashboard-guide-ko.mp4";
import dashboardGuideMs from "@/assets/videos/vvv-dashboard-guide-ms.mp4";
import dashboardGuidePl from "@/assets/videos/vvv-dashboard-guide-pl.mp4";
import dashboardGuideRu from "@/assets/videos/vvv-dashboard-guide-ru.mp4";
import dashboardGuideEs from "@/assets/videos/vvv-dashboard-guide-es.mp4";
import dashboardGuideTh from "@/assets/videos/vvv-dashboard-guide-th.mp4";
import dashboardGuideTr from "@/assets/videos/vvv-dashboard-guide-tr.mp4";

const userDetailStore = useUserDetailStore();
const { copy, copied } = useClipboard({ legacy: true })

watch(copied, (value) => {
    if (value) {
        alert("Copied to clipboard!");
    }
});

const extractGoogleSheetsId = (url) => {
    const regex = /\/d\/([a-zA-Z0-9-_]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
}

const shortenSheetUrl = computed(() => {
    if (userDetailStore.userDetail.googleSheetLink === null) return '';
    const sheetId = extractGoogleSheetsId(userDetailStore.userDetail.googleSheetLink);
    return `https://docs.google.com/spreadsheets/d/${sheetId}`;
});

onMounted(() => {
    useTitle("Bulk Claims | Very Viral Visuals");
    updateVideoUrl();
});

const videoUrl = ref(dashboardGuideEn);
const selectedLanguage = ref(1);

const videoUrlList = ref([
    { text: "English", value: 1, videoSrc: dashboardGuideEn },
    { text: "Vietnamese", value: 2, videoSrc: dashboardGuideVi },
    { text: "Chinese", value: 3, videoSrc: dashboardGuideZh },
    { text: "Dutch", value: 4, videoSrc: dashboardGuideNl },
    { text: "French", value: 5, videoSrc: dashboardGuideFr },
    { text: "German", value: 6, videoSrc: dashboardGuideDe },
    { text: "Indonesian", value: 7, videoSrc: dashboardGuideId },
    { text: "Italian", value: 8, videoSrc: dashboardGuideIt },
    { text: "Japanese", value: 9, videoSrc: dashboardGuideJa },
    { text: "Korean", value: 10, videoSrc: dashboardGuideKo },
    { text: "Malay", value: 11, videoSrc: dashboardGuideMs },
    { text: "Polish", value: 12, videoSrc: dashboardGuidePl },
    { text: "Russian", value: 13, videoSrc: dashboardGuideRu },
    { text: "Spanish", value: 14, videoSrc: dashboardGuideEs },
    { text: "Thai", value: 15, videoSrc: dashboardGuideTh },
    { text: "Turkish", value: 16, videoSrc: dashboardGuideTr }
]);

const updateVideoUrl = () => {
    const videoElement = document.getElementById('local-video');
    const selectedOption = videoUrlList.value.find(option => option.value === selectedLanguage.value);
    if (videoElement && selectedOption) {
        videoElement.pause();
        videoUrl.value = selectedOption.videoSrc;
        videoElement.load();
    }
};

watch(selectedLanguage, () => {
    updateVideoUrl();
});

const redirectToSheet = () => {
    window.open(`${shortenSheetUrl.value}`, '_blank');
};
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    gap: 5px;
}

.btn-outline-primary {
    color: var(--primary);
    border-color: var(--primary)
}

#local-video {
    width: 80%;
}
</style>
